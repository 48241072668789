<template>
  <section>
    <!-- School Profile Picture -->
    <div class="bg-gray-50">
      <profile-picture
        class="container max-w-screen-lg p-4 pt-8 pb-12 mx-auto"
      ></profile-picture>
    </div>

    <div>
      <school-identity
        class="container max-w-screen-lg p-4 pt-8 pb-12 mx-auto"
      ></school-identity>
    </div>

    <!-- School Introduction -->
    <div class="bg-gray-200">
      <short-introduction
        class="p-4 container max-w-screen-lg pt-14 pb-12 mx-auto"
      ></short-introduction>
    </div>

    <!-- School History Tree -->
    <div class="bg-gray-100">
      <history-tree
        class="p-4 container max-w-screen-lg pt-14 pb-12 mx-auto"
      ></history-tree>
    </div>
  </section>
</template>

<script>
export default {
  components: {
    ShortIntroduction: () =>
      import("@/components/SchoolHistory/ShortIntroduction.vue"),
    ProfilePicture: () =>
      import("@/components/SchoolHistory/ProfilePicture.vue"),
    HistoryTree: () => import("@/components/SchoolHistory/HistoryTree.vue"),
    SchoolIdentity: () =>
      import("@/components/SchoolHistory/SchoolIdentity.vue"),
  },
  name: "SchoolHistory",
};
</script>

<style lang="scss" scoped>
.content-container {
  @screen lg {
    @apply grid grid-cols-5;
  }
}
</style>